import React from "react";
import _ from "lodash";

import { htmlToReact, Link, safePrefix } from "../utils";

export default class SectionFeaturedspaces extends React.Component {
  render() {
    return (
      <section
        id={_.get(this.props, "section.section_id")}
        className={
          "block posts-block featured-block bg-" +
          _.get(this.props, "section.bg") +
          " outer"
        }
      >
        <div className="block-header inner-small">
          {_.get(this.props, "section.title") && (
            <h2 className="block-title">
              {_.get(this.props, "section.title")}
            </h2>
          )}
          {_.get(this.props, "section.subtitle") && (
            <p className="block-subtitle">
              {htmlToReact(_.get(this.props, "section.subtitle"))}
            </p>
          )}
        </div>
        <div className="inner">
          <div className="post-feed">
            {_.map(_.get(this.props, "section.spaces"), (space, space_idx) => (
              <div key={space_idx} className="post post-card">
                <div className="post-card-inside">
                  {_.get(space, "url") ? (
                    <Link
                      className="post-card-thumbnail"
                      to={safePrefix(_.get(space, "url"))}
                    >
                      <img
                        className="thumbnail lazyload"
                        data-src={safePrefix(_.get(space, "image"))}
                        alt={`${_.get(space, "name")} 3D model`}
                      />
                    </Link>
                  ) : (
                    <div className="post-card-thumbnail">
                      <img
                        className="thumbnail lazyload"
                        data-src={safePrefix(_.get(space, "image"))}
                        alt={`${_.get(space, "name")} 3D model`}
                      />
                    </div>
                  )}
                  <div className="post-card-content">
                    <header className="post-header">
                      <h6 className="post-title">
                        {_.get(space, "url") ? (
                          <Link to={safePrefix(_.get(space, "url"))}>
                            {_.get(space, "name")}
                          </Link>
                        ) : (
                          _.get(space, "name")
                        )}
                      </h6>
                    </header>
                    {_.get(space, "description") && (
                      <div className="post-excerpt">
                        <p>{_.get(space, "description")}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}
