import React from "react";
import { Link as GatsbyLink, navigate } from "gatsby";

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        onClick={() => {
          // on mobile, anchor links in same page are broken due to hidden overflow
          // we fix this by closing the menu and scrolling manually
          const mobileMenuOpened = document.body.classList.contains(
            "menu--opened"
          );
          const closeMobileMenu = () =>
            document.body.classList.toggle("menu--opened");
          const selfAnchorLink =
            to.split("#")[0] === window.location.pathname && to.includes("#");
          if (selfAnchorLink && mobileMenuOpened) {
            closeMobileMenu();
            navigate(to);
          }
        }}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={to} target="_blank" rel="noreferrer noopener" {...other}>
      {children}
    </a>
  );
};

export default Link;
