import React, { useState } from "react";

const initialInputs = { email: "" };

const SubscribeForm = ({
  buttonText,
  successText = "Done! Check your emails",
  errorText = "Oups! We couldn't register your email",
  endpoint,
  data = {}
}) => {
  const [inputs, setInputs] = useState(initialInputs);
  const [status, setStatus] = useState("default");

  const handleSubmit = event => {
    event && event.preventDefault();
    alert(
      "This is an archived website, please check our new site at www.smplrspace.com"
    );
  };

  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };

  const btnText = status => {
    switch (status) {
      case "loading":
        return "A moment please...";
      case "success":
        return successText;
      case "error":
        return errorText;
      case "after-error":
        return "Try again";
      default:
        return buttonText;
    }
  };

  return (
    <form className="subscribe-form" onSubmit={handleSubmit}>
      <div className="screen-reader-text">
        <label>
          Don't fill this out if you're human: <input name="bot-field" />
        </label>
      </div>
      <div className="form-row">
        <label>
          <span className="screen-reader-text">Email address</span>
          <input
            className="subscribe-email"
            type="email"
            name="email"
            placeholder="Enter your email"
            onChange={handleInputChange}
            value={inputs.email}
            required
          />
        </label>
      </div>
      <button
        className="button white large"
        type="submit"
        disabled={["loading", "success", "error"].includes(status)}
      >
        {btnText(status)}
      </button>
    </form>
  );
};

export default SubscribeForm;
