import React from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";

import { safePrefix, registerSmoothScroll, scrollTo } from "../utils";
import Header from "./Header";
import Footer from "./Footer";

// register smooth scroll
registerSmoothScroll('a[href*="#"]');

export default class Body extends React.Component {
  componentDidMount() {
    // fix missed scroll to anchor on first mount
    if (window.location.hash) {
      setTimeout(() => {
        scrollTo(window.location.hash);
      }, 500);
    }
  }

  render() {
    let title;
    if (
      _.get(this.props, "pageContext.frontmatter.title").startsWith(
        _.get(this.props, "pageContext.site.siteMetadata.title")
      )
    ) {
      title = _.get(this.props, "pageContext.frontmatter.title");
    } else if (!_.get(this.props, "pageContext.frontmatter.title")) {
      title = _.get(this.props, "pageContext.site.siteMetadata.title");
    } else {
      title = `${_.get(this.props, "pageContext.frontmatter.title")} - ${_.get(
        this.props,
        "pageContext.site.siteMetadata.title"
      )}`;
    }
    return (
      <React.Fragment>
        <Helmet>
          <html lang="en" />
          <title>{title}</title>
          <link
            rel="canonical"
            href={`${_.get(
              this.props,
              "pageContext.site.siteMetadata.base_url"
            )}${_.get(this.props, "pageContext.url")}`.replace(/\/$/, "")}
          />
          <meta
            name="description"
            content={
              _.get(this.props, "pageContext.frontmatter.description") ||
              _.get(this.props, "pageContext.site.siteMetadata.description")
            }
          />
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="google" content="notranslate" />
          <link rel="stylesheet" href={safePrefix("assets/css/main.css")} />
          {/* favicon */}
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#3a3c3c" />
          <meta name="msapplication-TileColor" content="#3a3c3c" />
          <meta name="theme-color" content="#f7f9fb" />
          {/* end of favicon */}
          {/* open graph tags (socials previews) */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta
            property="og:description"
            content={
              _.get(this.props, "pageContext.frontmatter.og_description") ||
              _.get(this.props, "pageContext.site.siteMetadata.description")
            }
          />
          <meta
            property="og:image"
            content={
              _.get(this.props, "pageContext.site.siteMetadata.base_url") +
              (_.get(this.props, "pageContext.frontmatter.og_image") ||
                _.get(this.props, "pageContext.site.siteMetadata.og_image"))
            }
          />
          <meta
            property="og:site_name"
            content={_.get(this.props, "pageContext.site.siteMetadata.title")}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@smplrspace" />
          {/* end of open graph tags */}
        </Helmet>
        <div
          id="page"
          className={
            "site palette-" +
            _.get(this.props, "pageContext.site.siteMetadata.palette")
          }
        >
          <Header {...this.props} />
          <main id="content" className="site-content">
            {this.props.children}
          </main>
          <Footer {...this.props} />
        </div>
      </React.Fragment>
    );
  }
}
