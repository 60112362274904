import React from "react";
import _ from "lodash";

import { safePrefix, htmlToReact, Link } from "../utils";

export default class SectionEmbed extends React.Component {
  render() {
    if (!_.get(this.props, "section.embed_id")) {
      return <p>Missing embed ID</p>;
    }
    const [embedSource, embedId] = _.get(this.props, "section.embed_id").split(
      ":"
    );
    if (
      !embedSource ||
      !embedId ||
      (embedSource !== "youtube" && embedSource !== "smplrspace")
    ) {
      return (
        <p>
          Error with embed ID:{" "}
          <code>{_.get(this.props, "section.embed_id")}</code>
        </p>
      );
    }
    const embedSrcUrl =
      embedSource === "smplrspace"
        ? `https://app.smplrspace.com/space/${embedId}`
        : `https://www.youtube-nocookie.com/embed/${embedId}?rel=0`;
    return (
      <section
        id={_.get(this.props, "section.section_id")}
        className={
          "block text-block bg-" + _.get(this.props, "section.bg") + " outer"
        }
      >
        <div className="block-header inner-small">
          <div className="grid grid-single-column">
            {_.get(this.props, "section.title") && (
              <h2 className="block-title">
                {_.get(this.props, "section.title")}
              </h2>
            )}
            {_.get(this.props, "section.subtitle") && (
              <p className="block-subtitle">
                {htmlToReact(_.get(this.props, "section.subtitle"))}
              </p>
            )}
            <div className="section-embed">
              <div
                className="gatsby-resp-iframe-wrapper"
                style={{
                  paddingBottom: "62.5%",
                  position: "relative",
                  height: 0,
                  overflow: "hidden",
                  margin: "0 0 1.66667em"
                }}
              >
                <div className="embedVideo-container">
                  <iframe
                    title={_.get(this.props, "section.title")}
                    data-src={embedSrcUrl}
                    className="embedVideo-iframe lazyload"
                    style={{
                      border: "0px none",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      overflow: "hidden"
                    }}
                    scrolling="no"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              {_.get(this.props, "section.actions") && (
                <p className="block-buttons">
                  {_.map(
                    _.get(this.props, "section.actions"),
                    (action, action_idx) => (
                      <Link
                        key={action_idx}
                        to={safePrefix(_.get(action, "url"))}
                        className="button secondary"
                      >
                        {_.get(action, "label")}
                      </Link>
                    )
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
