import React, { Fragment } from "react";
import _ from "lodash";

import { Link, safePrefix, htmlToReact } from "../utils";
import Social from "./Social";
import SubscribeForm from "./SubscribeForm";

export default class Footer extends React.Component {
  render() {
    return (
      <footer id="colophon" className="site-footer">
        <div className="footer-top outer">
          <div className="inner">
            <div className="footer-widgets">
              <div className="widget footer-branding">
                {_.get(
                  this.props,
                  "pageContext.site.siteMetadata.footer.logo_img"
                ) ? (
                  <p className="site-logo">
                    <Link to={safePrefix("/#")}>
                      <img
                        data-src={safePrefix(
                          _.get(
                            this.props,
                            "pageContext.site.siteMetadata.footer.logo_img"
                          )
                        )}
                        alt="Smplrspace"
                        className="lazyload"
                      />
                    </Link>
                  </p>
                ) : (
                  <p className="site-title">
                    <Link to={safePrefix("/")}>
                      {_.get(
                        this.props,
                        "pageContext.site.siteMetadata.header.title"
                      )}
                    </Link>
                  </p>
                )}
                {_.get(
                  this.props,
                  "pageContext.site.siteMetadata.footer.tagline"
                ) && (
                  <p className="site-description">
                    {_.get(
                      this.props,
                      "pageContext.site.siteMetadata.footer.tagline"
                    )}
                  </p>
                )}
                {_.get(
                  this.props,
                  "pageContext.site.siteMetadata.footer.tagline-image"
                ) && (
                  <img
                    data-src={safePrefix(
                      _.get(
                        this.props,
                        "pageContext.site.siteMetadata.footer.tagline-image"
                      )
                    )}
                    alt={_.get(
                      this.props,
                      "pageContext.site.siteMetadata.footer.tagline-image-alt"
                    )}
                    className="footer-image lazyload"
                  />
                )}
              </div>
              {_.get(
                this.props,
                "pageContext.site.siteMetadata.footer.has_nav"
              ) && (
                <nav className="widget footer-navigation">
                  <div className="footer-nav-inside">
                    {_.get(
                      this.props,
                      "pageContext.site.siteMetadata.footer.has_nav"
                    ) && (
                      <Fragment>
                        <div className="secondary-nav">
                          <h6 className="widget-title">
                            {_.get(
                              this.props,
                              "pageContext.site.data.menus.company.title"
                            )}
                          </h6>
                          <ul className="secondary-menu">
                            {_.map(
                              _.get(
                                this.props,
                                "pageContext.site.data.menus.company.items"
                              ),
                              (item, item_idx) => (
                                <li key={item_idx}>
                                  <Link to={safePrefix(_.get(item, "url"))}>
                                    {_.get(item, "title")}
                                  </Link>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                        <div className="secondary-nav">
                          <h6 className="widget-title">
                            {_.get(
                              this.props,
                              "pageContext.site.data.menus.community.title"
                            )}
                          </h6>
                          <ul className="secondary-menu">
                            {_.map(
                              _.get(
                                this.props,
                                "pageContext.site.data.menus.community.items"
                              ),
                              (item, item_idx) => (
                                <li className="footer-link" key={item_idx}>
                                  <Link to={safePrefix(_.get(item, "url"))}>
                                    {_.get(item, "title")}
                                  </Link>
                                </li>
                              )
                            )}
                            <li key="social-links">
                              {_.get(
                                this.props,
                                "pageContext.site.siteMetadata.footer.has_social"
                              ) && (
                                <div className="social-nav">
                                  <Social {...this.props} />
                                </div>
                              )}
                            </li>
                          </ul>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </nav>
              )}
              {_.get(
                this.props,
                "pageContext.site.siteMetadata.footer.has_subscribe"
              ) && (
                <div id="newsletter" className="widget footer-subscribe">
                  <h6 className="widget-title">
                    {_.get(
                      this.props,
                      "pageContext.site.siteMetadata.footer.subscribe_title"
                    )}
                  </h6>
                  {_.get(
                    this.props,
                    "pageContext.site.siteMetadata.footer.subscribe_content"
                  ) && (
                    <p>
                      {htmlToReact(
                        _.get(
                          this.props,
                          "pageContext.site.siteMetadata.footer.subscribe_content"
                        )
                      )}
                    </p>
                  )}
                  <SubscribeForm
                    buttonText="Subscribe"
                    endpoint="/api/signup"
                    data={{ type: "newsletter" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="site-info outer">
          <div className="inner">
            <div className="copyright">
              {htmlToReact(
                _.get(
                  this.props,
                  "pageContext.site.siteMetadata.footer.content"
                )
              )}
            </div>
            <div className="links">
              {_.map(
                _.get(this.props, "pageContext.site.siteMetadata.footer.links"),
                (link, link_idx) => (
                  <React.Fragment key={link_idx}>
                    {link_idx > 0 && <span> - </span>}
                    <Link
                      key={link_idx}
                      to={_.get(link, "url")}
                      {...(_.get(link, "new_window")
                        ? { target: "_blank", rel: "noopener" }
                        : null)}
                    >
                      {_.get(link, "text")}
                    </Link>
                  </React.Fragment>
                )
              )}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
