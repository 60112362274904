import React, { useEffect } from "react";
import _ from "lodash";

export default props => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.iubenda.com/iubenda.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <section
      id={_.get(props, "section.section_id")}
      className="block text-block outer"
    >
      <div className="inner">
        <div className="grid grid-single-column">
          {_.get(props, "section.title") && (
            <h2 className="block-title">{_.get(props, "section.title")}</h2>
          )}
          <a
            href={_.get(props, "section.link")}
            className="iubenda-nostyle no-brand iubenda-embed iub-body-embed"
            title={_.get(props, "section.link-text")}
          >
            {_.get(props, "section.link-text")}
          </a>
        </div>
      </div>
    </section>
  );
};
