import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FiLink, FiCheck } from "react-icons/fi";
import { Location } from "@reach/router";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyLink = ({ anchor, text, copiedText = "Copied" }) => {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied) {
      const timeoutId = setTimeout(() => {
        setCopied(false);
      }, 3000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [copied]);

  return (
    <Location>
      {({ location }) => {
        const link = anchor
          ? `${location.origin}${location.pathname}#${anchor}`
          : `${location.origin}${location.pathname}`;
        return (
          <CopyToClipboard text={link} onCopy={() => setCopied(true)}>
            {copied ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "0.5em"
                }}
              >
                <FiCheck className="link-icon link-icon-visible" />
                {text && copiedText}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "0.5em"
                }}
              >
                <FiLink className="link-icon" />
                {text}
              </div>
            )}
          </CopyToClipboard>
        );
      }}
    </Location>
  );
};

CopyLink.propTypes = {
  anchor: PropTypes.string
};

export default CopyLink;
