const options = {
  easing: "easeInOutQuint",
  durationMin: 600,
  durationMax: 1200,
  clip: true,
  offset: 84, // fixed header
  updateURL: true,
  popstate: true,
  topOnEmptyHash: true
};

export function registerSmoothScroll(selector) {
  if (typeof window !== "undefined") {
    new require("smooth-scroll")(selector, options);
  }
}

export function scrollTo(selector) {
  if (typeof window !== "undefined") {
    const scroll = new require("smooth-scroll")();
    scroll.animateScroll(
      document.querySelector(selector),
      document.querySelector("body"),
      options
    );
  }
}
