import React, { Fragment } from "react";
import _ from "lodash";

import { htmlToReact, markdownify } from "../utils";
import CtaButtons from "./CtaButtons";

export default class SectionPricing extends React.Component {
  state = {
    annualBilling: false
  };

  render() {
    return (
      <section
        id={_.get(this.props, "section.section_id")}
        className={
          "block pricing-block bg-" + _.get(this.props, "section.bg") + " outer"
        }
      >
        <div className="block-header inner-small">
          {_.get(this.props, "section.title") && (
            <h1 className="block-title">
              {_.get(this.props, "section.title")}
            </h1>
          )}
          {_.get(this.props, "section.subtitle") && (
            <p className="block-subtitle">
              {htmlToReact(_.get(this.props, "section.subtitle"))}
            </p>
          )}
        </div>
        <div className="pricing-annual-toggle">
          <button
            className={`pricing-toggle-btn ${
              !this.state.annualBilling ? "active" : ""
            }`}
            onClick={() => this.setState({ annualBilling: false })}
          >
            Monthly
          </button>
          <button
            className={`pricing-toggle-btn ${
              this.state.annualBilling ? "active" : ""
            }`}
            onClick={() => this.setState({ annualBilling: true })}
          >
            Annual
          </button>
          <p>
            Choose annual billing and get{" "}
            <strong>
              <mark>2 months free</mark>
            </strong>{" "}
            every year.
          </p>
        </div>
        {_.get(this.props, "section.pricingplans") && (
          <div className="inner">
            <div className="grid">
              {_.map(
                _.get(this.props, "section.pricingplans"),
                (plan, plan_idx) => {
                  const noDiscountPrice = _.get(plan, "price");
                  const price =
                    _.get(plan, "discounted_price") || noDiscountPrice;
                  const monthlyChargeNoDiscount = this.state.annualBilling
                    ? (noDiscountPrice * 10) / 12
                    : noDiscountPrice * 1;
                  const monthlyCharge = this.state.annualBilling
                    ? (price * 10) / 12
                    : price * 1;
                  const annualCharge = monthlyCharge * 12;
                  return (
                    <div
                      key={plan_idx}
                      className={
                        "cell plan" +
                        (_.get(plan, "highlight") ? " highlight" : "")
                      }
                    >
                      <div className="plan-inside">
                        <h3 className="plan-name">{_.get(plan, "title")}</h3>
                        {_.get(plan, "price") && (
                          <div className="plan-price">
                            {_.get(plan, "discounted_price") && (
                              <Fragment>
                                <span className="plan-price-nodiscount">
                                  ${Math.round(monthlyChargeNoDiscount)}
                                  <span className="promo-tag">
                                    LAUNCH DISCOUNT
                                  </span>
                                </span>
                              </Fragment>
                            )}
                            <span>${Math.round(monthlyCharge)}</span>
                            <span className="plan-price-schedule">
                              per month
                            </span>
                          </div>
                        )}
                        {this.state.annualBilling && (
                          <p className="billed-annually">
                            billed ${annualCharge} annually
                          </p>
                        )}
                        <div className="plan-details">
                          {markdownify(_.get(plan, "details"))}
                        </div>
                        {_.get(plan, "actions") && (
                          <CtaButtons
                            {...this.props}
                            actions={_.get(plan, "actions")}
                          />
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
        {_.get(this.props, "section.extra_information") && (
          <div className="block-content inner-small pricing-extra-info">
            {markdownify(_.get(this.props, "section.extra_information"))}
          </div>
        )}
      </section>
    );
  }
}
