import CtaButtons from "./CtaButtons";
import Footer from "./Footer";
import Header from "./Header";
import SectionContact from "./SectionContact";
import SectionContent from "./SectionContent";
import SectionContent1Column from "./SectionContent1Column";
import SectionCta from "./SectionCta";
import SectionEarlyaccess from "./SectionEarlyaccess";
import SectionFaq from "./SectionFaq";
import SectionFaqCategory from "./SectionFaqCategory";
import SectionFeaturedspaces from "./SectionFeaturedspaces";
import SectionFeatures from "./SectionFeatures";
import SectionHero from "./SectionHero";
import SectionHerofullwidth from "./SectionHerofullwidth";
import SectionIubenda from "./SectionIubenda";
import SectionPosts from "./SectionPosts";
import SectionPricing from "./SectionPricing";
import SectionReviews from "./SectionReviews";
import SectionSupport from "./SectionSupport";
import SectionEmbed from "./SectionEmbed";
import Social from "./Social";
import SubscribeForm from "./SubscribeForm";
import Layout from "./Layout";

export {
  CtaButtons,
  Footer,
  Header,
  SectionContact,
  SectionContent,
  SectionContent1Column,
  SectionCta,
  SectionEarlyaccess,
  SectionFaq,
  SectionFaqCategory,
  SectionFeaturedspaces,
  SectionFeatures,
  SectionHero,
  SectionHerofullwidth,
  SectionIubenda,
  SectionPosts,
  SectionPricing,
  SectionReviews,
  SectionSupport,
  SectionEmbed,
  Social,
  SubscribeForm,
  Layout
};

export default {
  CtaButtons,
  Footer,
  Header,
  SectionContact,
  SectionContent,
  SectionContent1Column,
  SectionCta,
  SectionEarlyaccess,
  SectionFaq,
  SectionFaqCategory,
  SectionFeaturedspaces,
  SectionFeatures,
  SectionHero,
  SectionHerofullwidth,
  SectionIubenda,
  SectionPosts,
  SectionPricing,
  SectionReviews,
  SectionSupport,
  SectionEmbed,
  Social,
  SubscribeForm,
  Layout
};
