import React, { Fragment } from "react";
import _ from "lodash";

import { htmlToReact, markdownify } from "../utils";
import CopyLink from "./CopyLink";

export default class SectionFaq extends React.Component {
  componentDidMount() {
    this.openLinkQuestion();
  }

  // we should also call this if the hash changes but seems gatsby doesn't emit the event
  // see https://github.com/gatsbyjs/gatsby/issues/7017#issuecomment-567829791
  openLinkQuestion = () => {
    if (window.location.hash) {
      // timeout to be sure accordions are ready
      setTimeout(() => {
        const accordions = document.querySelectorAll(".faq-accordion");
        accordions.forEach((accordion, accordionIndex) => {
          Array.from(accordion.getElementsByTagName("dt")).forEach(
            (question, questionIndex) => {
              if (window.location.hash === `#${question.id}`) {
                window.accordions[accordionIndex].open(questionIndex);
              }
            }
          );
        });
      }, 300);
    }
  };

  render() {
    return (
      <section
        id={_.get(this.props, "section.section_id")}
        className={
          "block faq-block bg-" + _.get(this.props, "section.bg") + " outer"
        }
      >
        <div className="inner-small">
          <div className="block-header">
            {_.get(this.props, "section.title") && (
              <h1 className="block-title">
                {_.get(this.props, "section.title")}
              </h1>
            )}
            {_.get(this.props, "section.subtitle") && (
              <p className="block-subtitle">
                {htmlToReact(_.get(this.props, "section.subtitle"))}
              </p>
            )}
          </div>
          {_.get(this.props, "section.faqitems") &&
            _.map(
              _.toPairs(
                _.groupBy(_.get(this.props, "section.faqitems"), faqitem =>
                  _.get(faqitem, "category")
                )
              ),
              ([category, faqitems]) => {
                const [, id, title] = category.split(" | ");
                return (
                  <Fragment key={category}>
                    <h4 id={id} className="faq-category">
                      {title}
                      <span>
                        <CopyLink anchor={id} />
                      </span>
                    </h4>
                    <dl className="faq-accordion">
                      {_.map(faqitems, (faqitem, faqitem_idx) => (
                        <React.Fragment key={faqitem_idx}>
                          <dt
                            key={`header-${faqitem_idx}`}
                            className="accordion-header"
                            id={_.get(faqitem, "id")}
                          >
                            <button className="accordion-trigger">
                              <div className="accordion-title">
                                {_.get(faqitem, "question")}
                              </div>
                              <div className="accordion-icon icon-plus" />
                            </button>
                          </dt>
                          <dd
                            key={`panel-${faqitem_idx}`}
                            className="accordion-panel"
                          >
                            <div className="accordion-content">
                              {markdownify(_.get(faqitem, "answer"))}
                              <CopyLink anchor={_.get(faqitem, "id")} />
                            </div>
                          </dd>
                        </React.Fragment>
                      ))}
                    </dl>
                  </Fragment>
                );
              }
            )}
        </div>
      </section>
    );
  }
}
