import React from "react";
import _ from "lodash";
import { Location } from "@reach/router";
import queryString from "query-string";

import { htmlToReact, markdownify, safePrefix } from "../utils";

export default class SectionSupport extends React.Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  state = {
    filesAttached: null,
    topic: ""
  };

  filesAttached = () => {
    this.setState({
      filesAttached: this.fileInput.current.files
    });
  };

  topicChanged = e => {
    this.setState({
      topic: e.target.value
    });
  };

  render() {
    let section = _.get(this.props, "section");
    return (
      <Location>
        {({ location }) => {
          const { code, message } = location.search
            ? queryString.parse(location.search)
            : {};
          if (code === "ok") {
            return (
              <section
                id={_.get(section, "section_id")}
                className={
                  "block contact-block bg-" + _.get(section, "bg") + " outer"
                }
              >
                <div className="block-header inner-small">
                  {_.get(section, "title") && (
                    <h1 className="block-title">{_.get(section, "title")}</h1>
                  )}
                  <p>
                    Your support request has been sent, we'll be in touch soon.
                  </p>
                </div>
              </section>
            );
          }
          if (code) {
            return (
              <section
                id={_.get(section, "section_id")}
                className={
                  "block contact-block bg-" + _.get(section, "bg") + " outer"
                }
              >
                <div className="block-header inner-small">
                  {_.get(section, "title") && (
                    <h1 className="block-title">{_.get(section, "title")}</h1>
                  )}
                  <p>
                    Sorry, there was an error and we could not send your support
                    request.
                    <br />
                    {message}
                    <br />
                    You can try again or send us an email.
                  </p>
                </div>
              </section>
            );
          }
          return (
            <section
              id={_.get(section, "section_id")}
              className={
                "block contact-block bg-" + _.get(section, "bg") + " outer"
              }
            >
              <div className="block-header inner-small">
                {_.get(section, "title") && (
                  <h1 className="block-title">{_.get(section, "title")}</h1>
                )}
                {_.get(section, "image") && (
                  <div className="cell block-preview">
                    <img
                      src={safePrefix(_.get(section, "image"))}
                      alt={_.get(section, "alt-text")}
                    />
                  </div>
                )}
                {_.get(section, "subtitle") && (
                  <p className="block-subtitle">
                    {htmlToReact(_.get(section, "subtitle"))}
                  </p>
                )}
              </div>
              <div className="block-content inner-medium">
                {markdownify(_.get(section, "content"))}
                <form
                  method="POST"
                  name="fa-form-1"
                  action="https://webhook.frontapp.com/forms/e52a6fe51816281c1f13/9422kZ-yy7mIKqM7QVKYTNfObe7q3zBBVwhyY45UcT_ZVI2iEDcIPlL5fYEMkSRpsoAl2MLLiPqSWnwW93-_v8r5RFu_yExuW4n9ogzgG77PyiyxLbU-9JuqKkbu"
                  encType="multipart/form-data"
                  acceptCharset="utf-8"
                >
                  <p className="form-row">
                    <label className="form-label" htmlFor="name">
                      Name*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-input"
                      required
                      placeholder="Your full name"
                    />
                  </p>
                  <p className="form-row">
                    <label className="form-label" htmlFor="email">
                      Email*
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-input"
                      required
                      placeholder="Your business email"
                    />
                  </p>
                  <p className="form-row">
                    <label className="form-label" htmlFor="topic">
                      Topic*
                    </label>
                    <input
                      type="hidden"
                      name="topic"
                      value={this.state.topic}
                    />
                    <select
                      className={`form-input ${
                        this.state.topic === "" ? "placeholder" : ""
                      }`}
                      required
                      onChange={this.topicChanged}
                      defaultValue=""
                    >
                      <option value="" disabled hidden>
                        Please select an option
                      </option>
                      <option>I have a question before I sign up</option>
                      <option>
                        I have a question regarding pricing and billing
                      </option>
                      <option>I am confused about how something works</option>
                      <option>I think something is not working properly</option>
                      <option>I would like to request a feature</option>
                      <option>I would like to speak with the sales team</option>
                      <option>I don't fit in the other boxes</option>
                    </select>
                  </p>
                  <p className="form-row">
                    <label className="form-label" htmlFor="body">
                      Message*
                    </label>
                    <textarea
                      name="body"
                      className="form-textarea"
                      rows="12"
                      required
                      placeholder="Go ahead, we're listening..."
                    />
                  </p>
                  <p className="form-row">
                    <label className="form-label">Attachments</label>
                    <span className="form-type-input">
                      <input
                        type="file"
                        multiple
                        name="attachment"
                        id="attachment"
                        ref={this.fileInput}
                        onChange={this.filesAttached}
                      />
                      <label htmlFor="attachment">
                        Upload screenshot / file
                      </label>
                      <span style={{ marginLeft: "1em" }}>
                        {this.state.filesAttached &&
                          Array.from(this.state.filesAttached)
                            .map(f => f.name)
                            .join(", ")}
                      </span>
                    </span>
                  </p>
                  <input
                    type="hidden"
                    name="autoreply-from"
                    value="support@smplrspace.com"
                  />
                  <input
                    type="hidden"
                    name="autoreply-sender-name"
                    value="Smplrspace Support"
                  />
                  <div id="html_element" />
                  <p className="form-row form-submit">
                    <input type="submit" value="Send" className="button" />
                  </p>
                </form>
                {_.get(section, "footer") &&
                  markdownify(_.get(section, "footer"))}
              </div>
            </section>
          );
        }}
      </Location>
    );
  }
}
