import React from "react";
import _ from "lodash";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaQuestion,
  FaYoutube
} from "react-icons/fa";

import { Link } from "../utils";

const FaIcon = ({ brand }) => {
  // icons: https://react-icons.netlify.com/#/search
  switch (brand.toLowerCase()) {
    case "facebook":
      return <FaFacebookF />;
    case "twitter":
      return <FaTwitter />;
    case "instagram":
      return <FaInstagram />;
    case "linkedin":
      return <FaLinkedinIn />;
    case "youtube":
      return <FaYoutube />;
    default:
      return <FaQuestion />;
  }
};

export default class Social extends React.Component {
  render() {
    return (
      <ul className="social-links">
        {_.map(
          _.get(this.props, "pageContext.site.data.social.links"),
          (link, link_idx) => (
            <li key={link_idx}>
              <Link aria-label={_.get(link, "title")} to={_.get(link, "url")}>
                <FaIcon brand={_.get(link, "title")} />
              </Link>
            </li>
          )
        )}
      </ul>
    );
  }
}
