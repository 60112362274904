import React from "react";
import _ from "lodash";
import { Location } from "@reach/router";
import queryString from "query-string";

import { htmlToReact, markdownify, safePrefix } from "../utils";

export default class SectionContact extends React.Component {
  state = {
    topic: ""
  };

  topicChanged = e => {
    this.setState({
      topic: e.target.value
    });
  };

  render() {
    let section = _.get(this.props, "section");
    return (
      <Location>
        {({ location }) => {
          const { code, message } = location.search
            ? queryString.parse(location.search)
            : {};
          if (code === "ok") {
            return (
              <section
                id={_.get(section, "section_id")}
                className={
                  "block contact-block bg-" + _.get(section, "bg") + " outer"
                }
              >
                <div className="block-header inner-small">
                  {_.get(section, "title") && (
                    <h1 className="block-title">{_.get(section, "title")}</h1>
                  )}
                  <p>Your message has been sent, we'll be in touch soon.</p>
                </div>
              </section>
            );
          }
          if (code) {
            return (
              <section
                id={_.get(section, "section_id")}
                className={
                  "block contact-block bg-" + _.get(section, "bg") + " outer"
                }
              >
                <div className="block-header inner-small">
                  {_.get(section, "title") && (
                    <h1 className="block-title">{_.get(section, "title")}</h1>
                  )}
                  <p>
                    Sorry, there was an error and we could not send your
                    message.
                    <br />
                    {message}
                    <br />
                    You can try again or send us an email.
                  </p>
                </div>
              </section>
            );
          }
          return (
            <section
              id={_.get(section, "section_id")}
              className={
                "block contact-block bg-" + _.get(section, "bg") + " outer"
              }
            >
              <div className="block-header inner-small">
                {_.get(section, "title") && (
                  <h1 className="block-title">{_.get(section, "title")}</h1>
                )}
                {_.get(section, "image") && (
                  <div className="cell block-preview">
                    <img
                      src={safePrefix(_.get(section, "image"))}
                      alt={_.get(section, "alt-text")}
                    />
                  </div>
                )}
                {_.get(section, "subtitle") && (
                  <p className="block-subtitle">
                    {htmlToReact(_.get(section, "subtitle"))}
                  </p>
                )}
              </div>
              <div className="block-content inner-medium">
                {markdownify(_.get(section, "content"))}
                <form
                  method="POST"
                  name="fa-form-1"
                  action="https://webhook.frontapp.com/forms/e52a6fe51816281c1f13/Wup_dwfD4HicpjwIEdpduVWEsFQQlQ7O-NmPGX-qn2j6r_fHIN1FpKDisnGE7-CKilLqn5FwDoYn7T66pHmyK2Jx84GWERbqkvmF7BsAXDZy0HeAPuciWUVcKboe"
                  encType="multipart/form-data"
                  acceptCharset="utf-8"
                >
                  <p className="form-row">
                    <label className="form-label" htmlFor="name">
                      Name*
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-input"
                      required
                      placeholder="Your full name"
                    />
                  </p>
                  <p className="form-row">
                    <label className="form-label" htmlFor="email">
                      Email*
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-input"
                      required
                      placeholder="Your business email"
                    />
                  </p>
                  <p className="form-row">
                    <label className="form-label" htmlFor="topic">
                      Topic*
                    </label>
                    <input
                      type="hidden"
                      name="topic"
                      value={this.state.topic}
                    />
                    <select
                      className={`form-input ${
                        this.state.topic === "" ? "placeholder" : ""
                      }`}
                      required
                      onChange={this.topicChanged}
                      defaultValue=""
                    >
                      <option value="" disabled hidden>
                        Please select an option
                      </option>
                      <option>I have a general feedback or enquiry</option>
                      <option>
                        I am interested in partnership or collaboration
                      </option>
                      <option>
                        I am from a real estate agency and would like to speak
                        to the sales team
                      </option>
                      <option>
                        I am an investor and would like to know more about
                        Smplrspace
                      </option>
                      <option>I have a press / media related enquiry</option>
                      <option>I don't fit in the other boxes</option>
                    </select>
                  </p>
                  <p className="form-row">
                    <label className="form-label" htmlFor="body">
                      Message*
                    </label>
                    <textarea
                      name="body"
                      className="form-textarea"
                      rows="12"
                      required
                      placeholder="To help us assist you better, please indicate your company, business title, and where you are based..."
                    />
                  </p>
                  <input
                    type="hidden"
                    name="autoreply-from"
                    value="hello@smplrspace.com"
                  />
                  <input
                    type="hidden"
                    name="autoreply-sender-name"
                    value="Smplrspace"
                  />
                  <div id="html_element" />
                  <p className="form-row form-submit">
                    <input
                      type="submit"
                      value="Get in touch"
                      className="button"
                    />
                  </p>
                </form>
                {_.get(section, "footer") &&
                  markdownify(_.get(section, "footer"))}
              </div>
            </section>
          );
        }}
      </Location>
    );
  }
}
