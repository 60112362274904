import React from "react";
import _ from "lodash";

import { safePrefix, markdownify, Link, toStyleObj } from "../utils";

export default class SectionHeroFullWidth extends React.Component {
  render() {
    return (
      <section
        id={_.get(this.props, "section.section_id")}
        className="block hero-full-width-block bg-gray outer"
      >
        {_.get(this.props, "section.video") ? (
          <video
            autoPlay
            muted
            loop
            className="bg-video"
            style={toStyleObj(
              `opacity: ${_.get(this.props, "section.video-opacity")}`
            )}
            alt={_.get(this.props, "section.alt-text")}
          >
            <source
              src={safePrefix(_.get(this.props, "section.video"))}
              type="video/mp4"
            />
          </video>
        ) : (
          _.get(this.props, "section.image") && (
            <div
              className="bg-img"
              style={toStyleObj(
                `background-image: url('${safePrefix(
                  _.get(this.props, "section.image")
                )}'); opacity: ${_.get(this.props, "section.image-opacity")}`
              )}
            />
          )
        )}
        <div className="inner">
          <div className="grid">
            <div className="cell block-content">
              {_.get(this.props, "section.title") && (
                <h1 className="block-title underline">
                  {_.get(this.props, "section.title")}
                </h1>
              )}
              <div className="block-copy">
                {markdownify(_.get(this.props, "section.content"))}
              </div>
              {_.get(this.props, "section.actions") && (
                <p className="block-buttons">
                  {_.map(
                    _.get(this.props, "section.actions"),
                    (action, action_idx) => (
                      <Link
                        key={action_idx}
                        to={safePrefix(_.get(action, "url"))}
                        className="button large"
                      >
                        {_.get(action, "label")}
                      </Link>
                    )
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
