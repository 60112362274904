import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";

import { Link, safePrefix } from "../utils";

export default ({ menu }) => {
  const [open, setOpen] = useState(false);
  const [visited, setVisited] = useState(false);
  return (
    <li
      className={`menu-item with-submenu ${
        open ? "open" : visited ? "closed" : ""
      }`}
      onClick={() => {
        setOpen(!open);
        setVisited(true);
      }}
    >
      <span className="title">{menu.title}</span> <FiChevronDown />
      <div className="submenu">
        <div className="inner">
          <ul>
            {menu.subitems.map((subitem, index) => (
              <li key={index} className="submenu-item">
                <Link to={safePrefix(subitem.url)}>
                  <strong>{subitem.title}</strong>
                  <span>{subitem.description}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </li>
  );
};
